import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
        {/*
            <ul className="icons">
                <li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="#" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="#" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
                <li><a href="#" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                <li><a href="#" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
            */}
            <ul className="copyright">
                <li><Link to="/terms">Terms of Use</Link></li>
                <li><Link to="/privacy">Privacy Notice</Link></li>
                <li>&copy; 2019 Great Harbour Trading Ltd</li><li>Site: <a href="https://searched.io/">Searched</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
