import React from 'react'

const ContactForm = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
            <form method="POST" action="https://formspree.io/contactform@greatharbour.io">
              <div className="field half first">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" placeholder="" />
              </div>
              <div className="field half">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" placeholder="" />
              </div>
              <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea name="message" id="message" rows="6" placeholder=""></textarea>
              </div>
              <ul className="actions">
                  <li><input type="submit" value="Send Message" className="special" /></li>
                  <li><input type="reset" value="Clear" /></li>
              </ul>
            </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:info@greatharbour.io">info@greatharbour.io</a>
                    </div>
                </section>
                {/*
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <span>(000) 000-0000 x12387</span>
                    </div>
                </section>
                */}
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>1/F Columbus Centre
                        PO Box 2283, Road Town<br />
                        Nashville, Tortola VG 1110<br />
                        British Virgin Islands</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default ContactForm
